/* En fonts.css */

/* Fuente Times CG */
@font-face {
  font-family: "Times CG";
  src: url("../assets/font/TimesCG.woff2") format("woff2"),
    url("../assets/font/TimesCG.woff") format("woff"),
    url("../assets/font/TimesCG.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Fuente Times CG Italic */
@font-face {
  font-family: "Times CG Italic";
  src: url("../assets/font/TimesCG-Italic.woff2") format("woff2"),
    url("../assets/font/TimesCG-Italic.woff") format("woff"),
    url("../assets/font/TimesCG-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

/* Fuente Times CG Bold */
@font-face {
  font-family: "Times CG Bold";
  src: url("../assets/font/TimesCG-Bold.woff2") format("woff2"),
    url("../assets/font/TimesCG-Bold.woff") format("woff"),
    url("../assets/font/TimesCG-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

/* Fuente Times CG Bold Italic */
@font-face {
  font-family: "Times CG Bold Italic";
  src: url("../assets/font/TimesCG-BoldItalic.woff2") format("woff2"),
    url("../assets/font/TimesCG-BoldItalic.woff") format("woff"),
    url("../assets/font/TimesCG-Bold-Italic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

/* Fuente Tan Pearl */
@font-face {
  font-family: "TAN PEARL";
  src: url("../assets/font/tan-pearl.woff2") format("woff2"),
    url("../assets/font/tan-pearl.woff") format("woff"),
    url("../assets/font/tan-pearl.ttf") format("truetype"),
    url("../assets/font/tan-pearl.eot") format("embedded-opentype"),
    url("../assets/font/tan-pearl.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
