/* src/style/App.css */

/* General reset for margins and paddings */

.green-bar {
  width: 100%;
  height: fit-content;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #406252;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.name-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
}

.name-block h3 {
  color: white;
  font-size: 30px;
  font-family: "Times CG", sans-serif;
  font-weight: 200;
  letter-spacing: 2px;
  word-wrap: break-word;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos específicos para la versión móvil */
@media (max-width: 768px) {
  .green-bar {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .name-block {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .name-block h3 {
    font-size: 0px;
    letter-spacing: 1px;
    background: #406252;
  }

  .logo-container {
    width: 50px; /* Ajusta el tamaño del logo aquí */
    height: auto;
  }
  .logo {
    height: 20px;
  }
}
.logo {
  height: 60px;
}

section {
  padding: 100px 0; /* Ajusta según sea necesario */
}

/* Estilos para el componente Hero */
.hero {
  width: 100%;
  height: auto;
  position: relative;
}

.hero-image {
  width: 100%;
  height: 100%;
}

.hero-image img {
  width: 100%;
  height: auto;
}

.hero-content {
  width: 421px;
  height: 162px;
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -5%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.hero-content h1 {
  text-align: center;
  color: white;
  font-size: 80px;
  font-family: "Times CG", sans-serif;
  font-weight: 200;
  word-wrap: break-word;
}

.hero-content p {
  text-align: center;
  color: white;
  font-size: 40px;
  font-family: "Times CG", sans-serif;
  font-weight: 400;
  letter-spacing: 6px;
  word-wrap: break-word;
  margin-top: 24px;
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 768px) {
  .hero-image img {
    content: url("../assets/img/Hero_mobile.png");
  }
}

/* Invitacion */
.invitation-container {
  display: flex;
  flex-direction: row;
  width: 99%;
}

.invitation-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.invitation-content {
  flex: 1 1 0;
  padding: 6px 6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 64px;
}

.invitation-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.invitation-title h2 {
  width: 100%;
  color: #a0ae93;
  font-size: 48px;
  font-family: "TAN PEARL", sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.invitation-subtitle {
  padding-left: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.invitation-subtitle h3 {
  margin-top: -0.5rem;
  width: 100%;
  color: black;
  font-size: 28px;
  font-family: "Times CG", sans-serif;
  font-weight: 400;
  letter-spacing: 3.6px;
  word-wrap: break-word;
}

.invitation-text {
  width: 500px;
  color: black;
  font-size: 20px;
  font-family: "Times CG", sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.invitation-button {
  padding: 16px;
  background: #406252;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: none;
  color: white;
  font-size: 16px;
  font-family: "Times CG", sans-serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  word-wrap: break-word;
  cursor: pointer;
}

.invitation-image {
  width: auto;
  height: 739px;
  position: relative;
  margin-top: 1rem;
}

.invitation-image img {
  padding-right: 6rem;
}

/* Contador */
/* Estilos generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.countdown-container {
  width: 100%;
  padding: 32px 0;
  background: #406252;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.time-blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 82px;
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.time-value {
  color: white;
  font-size: 96px;
  font-family: "Times CG", sans-serif;
  font-weight: 400;
  text-align: center;
}

.time-label {
  color: white;
  font-size: 32px;
  font-family: "TAN PEARL", sans-serif;
  font-weight: 400;
  text-align: center;
}

.divider {
  width: 0;
  height: 96px; /* Ajusta la altura para centrar mejor */
  border-left: 3px solid white;
}
/* Estilos para la sección de video */
.video-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Puedes ajustar este color si deseas un fondo diferente */
}

.video-element {
  width: 100%;
  height: auto;
}
/* Estilos para la sección de código de vestimenta */
.dress-code-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #f8f8f8; /* Puedes ajustar este color si deseas un fondo diferente */
}

.dress-code-container {
  text-align: center;
}

.dress-code-image {
  width: 80%;
  height: auto;
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 768px) {
  .dress-code-image {
    content: url("../assets/img/CodigoVestimenta_mobile.png");
  }
}

/* Estilos para la sección de la frase */
.quote-section-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  background-color: #f8f8f8; /* Puedes ajustar este color si deseas un fondo diferente */
}

.quote-section-container {
  text-align: center;
}

.quote-section-image {
  width: 100%;
  height: auto;
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 768px) {
  .quote-section-image {
    width: 100%;
    content: url("../assets/img/Frase_mobile.png");
  }
}

/* Estilos para la sección del programa */
.program-section-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #f8f8f8; /* Puedes ajustar este color si deseas un fondo diferente */
}

.program-section-image {
  max-width: 80%;
  height: auto;
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 768px) {
  .program-section-image {
    width: 100%;
    content: url("../assets/img/Progama_mobile.png");
  }
}
/* Estilos para la sección de ubicación */
.location-section-container {
  width: 100%;
  padding: 32px 0;
  background: #406252;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  text-align: center;
  color: white;
  font-family: "Times CG", sans-serif;
}

.location-section-content h2 {
  font-size: 32px;
  font-family: "TAN PEARL", sans-serif;
  font-weight: 400;
  letter-spacing: 1.6px;
}

.location-section-content p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.9px;
  margin: 16px 0;
}

.location-section-map {
  align-items: center;
  width: 70%;
  height: auto;
  margin: 32px 0;
}
.location-section-buttons {
  display: flex;
  gap: 10px;
}

.location-section-button {
  padding: 10px 20px;
  font-size: 16px;
  background: #f9f9f9;
  border-radius: 12px;
  border: none;
  color: #406252;
  letter-spacing: 1.2px;
  cursor: pointer;
  text-decoration: none;
  font-family: "Times CG", sans-serif;
  transition: background-color 0.3s;
}

.location-section-button:hover {
  background-color: #406252ca;
}

/* Estilos para la sección de RSVP */
.rsvp-section-container {
  width: 100%;
  padding: 84px 240px;
  background: #ffffff;
  text-align: center;
  color: #a0ae93;
  font-family: "TAN PEARL", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rsvp-section-container h2 {
  font-size: 32px;
  margin-bottom: 32px;
}

.rsvp-form {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.rsvp-form-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.rsvp-form-group input,
.rsvp-form-group select,
textarea {
  flex: 1;
  padding: 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 16px;
  color: #666666;
  font-family: "Times CG", sans-serif;
}

textarea {
  width: 100%;
  resize: none;
}

.rsvp-submit-button {
  padding: 12px 16px;
  background: #c7cfb7;
  border-radius: 12px;
  color: #406252;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.2px;
  cursor: pointer;
  border: none;
  font-family: "Times CG", sans-serif;
}
/* Banner */
.banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.banner-image {
  max-width: 100%;
  height: auto;
}
.playlist-section {
  width: 100%;
  padding: 64px 124px;
  text-align: center;
}

.playlist-section .title {
  color: #406252;
  font-size: 32px;
  font-family: "Times CG";
  font-weight: 700;
  letter-spacing: 1.6;
}

.playlist-section .title span {
  font-weight: 400;
}

.playlist-section .add-song {
  padding: 16px;
  border-radius: 12px;
  margin-top: 32px;
}

.playlist-section .add-song-title {
  color: #406252;
  font-size: 24px;
  font-family: "Times CG";
  font-weight: 700;
  letter-spacing: 1.2;
}

.playlist-section iframe {
  border-radius: 12px;
  width: 100%;
  height: 352px;
  margin-top: 16px;
  background-color: #406252;
}

/* Footer */
.footer-container {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 10px;
}
.green-bar-2 {
  width: 100%;
  height: 50px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #406252;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-2 {
  height: 30px;
}
.footer-item {
  flex: 1 1 0;
}

.footer-title {
  color: white;
  font-size: 14px;
  font-family: "Times CG", serif;
  font-weight: 400;
  letter-spacing: 0.7px;
  word-wrap: break-word;
}

.footer-logo {
  width: 90px;
  height: 54px;
  position: relative;
}

.footer-date {
  text-align: right;
  color: white;
  font-size: 14px;
  font-family: "Times CG", serif;
  font-weight: 400;
  letter-spacing: 0.7px;
  word-wrap: break-word;
}

.footer-couple {
  text-align: center;
  color: #406252;
  font-size: 16px;
  font-family: "TAN PEARL", sans-serif;
  font-weight: 400;
  letter-spacing: 0.8px;
  word-wrap: break-word;
}

/* Ajustes en style.css */

.rsvp-form-group {
  position: relative; /* Necesario para que la lista de sugerencias se posicione correctamente */
}

.suggestions-list {
  position: absolute;
  top: 100%; /* Posiciona la lista justo debajo del campo de texto */
  left: 0;
  width: 50%; /* Asegúrate de que la lista ocupe el ancho del campo de texto */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Asegura que la lista esté encima de otros elementos */
  max-height: 200px; /* Opcional: limita la altura para evitar que la lista se desborde */
  overflow-y: auto; /* Permite el desplazamiento si hay muchas sugerencias */
  font-size: 0.5rem; /* Tamaño de fuente más pequeño, ajustable */
  text-align: left; /* Alinea el texto a la izquierda */
}

.suggestions-list .invitation-text {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list .invitation-text:hover {
  background-color: #f0f0f0;
}

/* General reset for margins and paddings */

/* Para pantallas pequeñas */
@media (max-width: 768px) {
  .hero-content {
    width: 90%;
    height: auto;
    top: 60%;
  }

  .hero-content h1 {
    font-size: 40px;
  }

  .hero-content p {
    font-size: 20px;
    margin-top: 10px;
  }

  .invitation-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .invitation-content {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 6px 2rem;
    gap: 20px;
  }

  .invitation-title h2 {
    font-size: 36px;
  }

  .invitation-subtitle h3 {
    font-size: 20px;
    width: 213px;
    margin-top: -0.47rem;
  }
  .invitation-subtitle {
    padding-left: 147px;
  }

  .invitation-text {
    width: 100%;
    font-size: 16px;
  }

  .invitation-button {
    font-size: 14px;
    padding: 12px;
  }

  .invitation-image {
    display: flex;
    justify-content: center;
    height: auto;
    max-width: 100;
  }
  .invitation-image img {
    width: 85%;
    padding-right: 0;
  }

  .time-value {
    font-size: 60px;
  }

  .time-label {
    font-size: 24px;
  }

  .program-section-image {
    max-width: 100% !important;
  }

  .location-section-content {
    width: 100%;
    padding: 16px;
  }

  .location-section-content h2 {
    font-size: 24px;
  }

  .location-section-content p {
    font-size: 16px;
  }

  .location-section-map {
    width: 100%;
  }

  .location-section-button {
    font-size: 20px;
    padding: 12px 24px;
  }

  .rsvp-section-container {
    padding: 40px 16px;
  }

  .rsvp-form-group {
    flex-direction: column;
    gap: 16px;
  }

  .rsvp-form-group input,
  .rsvp-form-group select,
  textarea {
    width: 100%;
  }

  .rsvp-submit-button {
    font-size: 20px;
    padding: 12px 24px;
  }

  .banner-container {
    padding-top: 10px;
  }

  .playlist-section {
    padding: 32px 16px;
  }

  .playlist-section .title {
    font-size: 18px;
  }
  .playlist-section .title span {
    font-weight: 200;
  }

  .playlist-section .add-song {
    margin-top: 0px;
    padding: 8px;
  }

  .playlist-section iframe {
    height: 200px;
  }

  .footer-container {
    height: auto;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-title {
    font-size: 12px;
  }

  .footer-logo {
    width: 60px;
  }

  .footer-date {
    font-size: 12px;
  }

  .footer-couple {
    font-size: 14px;
  }

  .suggestions-list {
    width: 100%;
    font-size: 0.8rem; /* Ajusta según sea necesario */
  }
}

/* Countdown container */
.countdown-container {
  width: 100%;
  padding: 32px 0;
  background: #406252;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.countdown-title {
  color: white;
  font-size: 30px;
  font-family: "Times CG", sans-serif;
  font-weight: 200;
  letter-spacing: 3.1px;
  text-align: center;
}

/* Time blocks */
.time-blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 82px;
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.time-value {
  color: white;
  font-size: 96px;
  font-family: "Times CG", sans-serif;
  font-weight: 400;
  text-align: center;
}

.time-label {
  color: white;
  font-size: 32px;
  font-family: "TAN PEARL", sans-serif;
  font-weight: 400;
  text-align: center;
}

/* Divider */
.divider {
  width: 0;
  height: 96px;
  border-left: 3px solid white;
}

/* Media Queries */
@media (max-width: 768px) {
  .countdown-title {
    font-size: 17px;
    letter-spacing: 1.1px;
    font-weight: 100; /* Ajusta según sea necesario */
  }

  .time-value {
    font-size: 24px; /* Ajusta según sea necesario */
  }

  .time-label {
    font-size: 16px; /* Ajusta según sea necesario */
  }

  .divider {
    height: 48px; /* Ajusta según sea necesario */
  }

  .time-blocks {
    gap: 32px; /* Ajusta según sea necesario */
  }
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
  .lluvia-de-sobres-title {
    font-size: 20;
  }
}

.lluvia-de-sobres-container {
  width: 100%;
  height: 53px;

  background: #406252;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lluvia-de-sobres-content {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

.lluvia-de-sobres-title {
  text-align: center;
  color: white;
  font-size: 24px;
  font-family: "Times CG", serif;
  font-weight: 100;
  letter-spacing: 1px;
}
.playlist-button {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #406252; /* Color verde de Spotify */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-family: "Times CG", serif;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 1px;
  text-align: center;
}

.playlist-button:hover {
  background-color: #406252; /* Color verde más claro de Spotify */
}
